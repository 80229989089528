<template>
  <v-container>
    <v-card v-if="vendor_image || vendor_name || vendor_orn || vendor_emails.length > 0 || vendor_phones.length > 0 || vendor_websites.length > 0 || vendor_addresses.length > 0">
      <v-card-text>
        <v-row justify="center" align="center">
          <v-col cols="12" sm="6" class="pb-0 pt-0">
            <img style="width: 40%" :src="vendor_image" alt="Logo" title="Logo">
          </v-col>
          <v-col cols="12" sm="6" class="pb-0 pt-0">
            <div class="float-right">
              <h2 style="color: #000000" v-if="vendor_name">{{vendor_name}}</h2>
              <p v-if="vendor_orn"><strong>ORN:
                {{vendor_orn}}
              </strong></p>
              <p class="custom-item-comma" v-if="vendor_emails.length > 0"><strong>Email(s):
                <a :href="'mailto:' + email" v-for="(email, eind) in vendor_emails" :key="eind">
                  {{ email }}<span class="comma">,</span>
                </a>
              </strong></p>
              <p class="custom-item-comma" v-if="vendor_phones.length > 0"><strong>Phone(s):
                <a :href="'tel:' + phone" v-for="(phone, pind) in vendor_phones" :key="pind">
                  {{ phone }}<span class="comma">,</span>
                </a>
              </strong></p>
              <p class="custom-item-comma" v-if="vendor_websites.length > 0"><strong>Website(s):
                <a :href="'//'+web" target="_blank" v-for="(web, wind) in vendor_websites" :key="wind">
                  {{ web }}<span class="comma">,</span>
                </a>
              </strong></p>
              <p class="custom-item-comma" v-if="vendor_addresses.length > 0"><strong>Address(es):
                <a v-for="(add, aind) in vendor_addresses" :key="aind">
                  {{ add }}<span class="comma">,</span>
                </a>
              </strong></p>
            </div>
          </v-col>
          <!--      <v-card flat>-->
          <!--        <v-card-text>-->
          <!--          <div v-if="vendor_image">-->
          <!--            <img style="width: 20%" :src="vendor_image" alt="Logo" title="Logo">-->
          <!--          </div>-->
          <!--          <div v-if="vendor_emails" class="float-right">-->
          <!--            <img style="width: 20%" :src="vendor_image" alt="Logo" title="Logo">-->
          <!--          </div>-->
          <!--        </v-card-text>-->
          <!--      </v-card>-->
          <!--      <v-col cols="12" sm="12">-->
          <!--        <a href="https://www.afsrealestate.com" target="_blank">-->
          <!--          <img style="width: 100%" src="media/company/header.jpg" alt="Logo" title="AFS Real Estate">-->
          <!--        </a>-->
          <!--      </v-col>-->
        </v-row>
      </v-card-text>
    </v-card>
    <v-divider></v-divider>
<!--    <v-row v-if="detailItem.hasOwnProperty('id')">-->
<!--      <v-col cols="12" sm="12">-->
<!--        <b-alert-->
<!--            show-->
<!--            variant="light"-->
<!--            class="alert alert-custom alert-success alert-shadow fade show gutter-b mb-0"-->
<!--        >-->
<!--          <div class="alert-text mt-2 text-center">-->
<!--            <h1 style="color:#000000;"><b>Welcome to {{ $route.query.location_id }}</b></h1>-->
<!--          </div>-->
<!--        </b-alert>-->
<!--      </v-col>-->
<!--    </v-row>-->
    <v-card v-if="detailItem.hasOwnProperty('id')">
      <v-card-text
          v-if="$route.query.location_ref === 'development'">
        <ReportDevelopmentDetails :item="detailItem"/>
      </v-card-text>
      <v-card-text v-if="$route.query.location_ref === 'project'">
        <ReportProjectDetails :item="detailItem"/>
      </v-card-text>
    </v-card>
    <v-row>
      <v-col cols="12" sm="12">
        <b-alert
            show
            variant="light"
            class="alert alert-custom alert-success alert-shadow fade show gutter-b mb-0"
        >
          <div class="alert-text mt-2 text-center">
            <h1 style="color: #000000"><b>Report as per searching criteria selected below:</b></h1>
          </div>
        </b-alert>
      </v-col>
    </v-row>
    <v-card class="card-custom bg-gray-100 card-stretch gutter-b">
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header class="custom-expansion-panel-header" color="#00004d">
            <div class="text-center font-weight-bold">
              Search/Filters
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-form @submit.stop.prevent="hitAdvanceSearch">
              <v-row>
                <v-col cols="12" sm="4">
                  <v-select
                      dense outlined
                      hide-details
                      clearable
                      @change="drChange"
                      :items="dateRangeFilter"
                      v-model="searchFormData.drSearch"
                      item-text="title"
                      item-value="value"
                      label="Date Range"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field type="date" dense outlined hide-details v-model="searchFormData.fromDateSearch"
                                label="From Date"></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field type="date" dense outlined hide-details v-model="searchFormData.toDateSearch"
                                label="To Date"></v-text-field>
                </v-col>

                <v-col cols="12" sm="2">
                  <v-text-field :counter="10"
                                maxLength="10"
                                dense outlined
                                hide-details
                                maxlength="10" v-model="searchFormData.minPriceSearch"
                                label="Min Price"></v-text-field>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-text-field :counter="10"
                                maxLength="10"
                                dense outlined
                                hide-details
                                maxlength="10" v-model="searchFormData.maxPriceSearch"
                                label="Max Price"></v-text-field>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-text-field :counter="10"
                                maxLength="10"
                                dense outlined
                                hide-details
                                maxlength="10" v-model="searchFormData.minPricePsfSearch"
                                label="Min Price Per SqFt"></v-text-field>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-text-field :counter="10"
                                maxLength="10"
                                dense outlined
                                hide-details
                                maxlength="10" v-model="searchFormData.maxPricePsfSearch"
                                label="Max Price Per SqFt"></v-text-field>
                </v-col>

                <v-col cols="12" sm="2">
                  <v-text-field :counter="10"
                                maxLength="10"
                                dense outlined
                                hide-details
                                maxlength="10" v-model="searchFormData.minBuaSearch"
                                label="Min Built Up Area"></v-text-field>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-text-field :counter="10"
                                maxLength="10"
                                dense outlined
                                hide-details
                                maxlength="10" v-model="searchFormData.maxBuaSearch"
                                label="Max Built Up Area"></v-text-field>
                </v-col>

                <v-col cols="12" sm="2">
                  <v-text-field :counter="10"
                                maxLength="10"
                                dense outlined
                                hide-details
                                maxlength="10" v-model="searchFormData.minPaSearch"
                                label="Min Plot Area"></v-text-field>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-text-field :counter="10"
                                maxLength="10"
                                dense outlined
                                hide-details
                                maxlength="10" v-model="searchFormData.maxPaSearch"
                                label="Max Plot Area"></v-text-field>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-autocomplete
                      dense outlined
                      clearable
                      hide-details
                      :items="bedsFilter"
                      v-model="searchFormData.bedSearch"
                      item-text="title"
                      item-value="value"
                      label="Beds"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-autocomplete
                      dense outlined
                      clearable
                      hide-details
                      :items="unitTypesFilter"
                      v-model="searchFormData.unitTypeSearch"
                      item-text="title"
                      item-value="value"
                      label="Property Type"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-select
                      dense outlined
                      clearable
                      hide-details
                      :items="primaryResaleFilter"
                      v-model="searchFormData.prSearch"
                      item-text="title"
                      item-value="value"
                      label="Primary/Resale"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-select
                      dense outlined
                      clearable
                      hide-details
                      :items="priceSortFilter"
                      v-model="searchFormData.priceSortSearch"
                      item-text="title"
                      item-value="value"
                      label="Price Sort"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-select
                      dense
                      outlined
                      clearable
                      hide-details
                      :items="typeSortFilter"
                      v-model="searchFormData.typeSearch"
                      item-text="title"
                      item-value="value"
                      label="Type"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <button :disabled="loading" type="submit" style="width: 100%"
                          class="btn btn-success theme-btn ml-auto mr-2">
                    Search
                    <v-icon class="ml-2" small>fas fa-search</v-icon>
                  </button>
                </v-col>
                <v-col cols="12" sm="6">
                  <button :disabled="loading" style="width: 100%" @click="resetSearchForm"
                          class="btn btn-primary ml-auto">
                    Reset
                    <v-icon class="ml-2" small>fas fa-times</v-icon>
                  </button>
                </v-col>
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-card-title class="pb-0">
        Transactions ({{ addTSBVZ(totalRecords) }})
      </v-card-title>
      <v-row>
        <v-col cols="12">
          <v-data-table
              dense
              :headers="headers"
              :items="items"
              fixed-header
              height="500"
              :options.sync="pagination"
              :server-items-length="totalRecords"
              :footer-props="{
                    itemsPerPageOptions: rowsPerPageItems,
                    showFirstLastPage: true,
                  }"
              :loading="loading"
              mobile-breakpoint="100"
              class="elevation-0 custom-td-table">
            <template v-slot:item.type="{ item }">
              <div class="text-truncate">
                <p v-if="item.type === 0">Residential</p>
                <p v-else>Commercial</p>
              </div>
            </template>
            <template v-slot:item.location="{ item }">
              <div class="text-truncate">
                <p v-html="item.location"></p>
              </div>
            </template>
            <template v-slot:item.date="{ item }">
              <div class="text-truncate">
                <p v-html="formatMomentDate(item.date)"></p>
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-divider class="mb-0"></v-divider>
      <v-row>
        <v-col cols="12">
          <v-card-title class="pt-0">
            Yearly Transactions and Sales
          </v-card-title>
          <div class="bg-white">
            <apexchart ref="yearlyGraph" type="line" height="350" :options="chartOptions" :series="series"></apexchart>
          </div>
        </v-col>
      </v-row>
      <v-divider class="mb-0"></v-divider>
      <v-row>
        <v-col cols="12">
          <v-card-title class="pt-0">
            Transactions and Sales by Property Types
          </v-card-title>
          <div class="bg-white">
            <apexchart ref="ptGraph" type="line" height="350" :options="chartOptions1" :series="series1"></apexchart>
          </div>
        </v-col>
      </v-row>
      <v-divider class="mb-0"></v-divider>
      <v-row>
        <v-col cols="12">
          <v-card-title class="pt-0">
            Average Price Per Square Feet
          </v-card-title>
          <div class="bg-white">
            <apexchart ref="avgPpsfGraph" type="line" height="350" :options="chartOptions2"
                       :series="series2"></apexchart>
          </div>
        </v-col>
      </v-row>
<!--      <v-divider class="mb-0"></v-divider>-->
<!--      <v-row>-->
<!--        <v-col cols="12">-->
<!--          <v-card-title class="pt-0">-->
<!--            Pricing Summary-->
<!--          </v-card-title>-->
<!--          <div class="bg-white">-->
<!--            <v-simple-table>-->
<!--              <template v-slot:default>-->
<!--                <thead>-->
<!--                <tr>-->
<!--                  <th class="text-left">-->
<!--                    No. Of Beds-->
<!--                  </th>-->
<!--                  <th class="text-left">-->
<!--                    No. Transactions-->
<!--                  </th>-->
<!--                  <th class="text-left">-->
<!--                    Avg Price (AED)-->
<!--                  </th>-->
<!--                  <th class="text-left">-->
<!--                    Min Price (AED)-->
<!--                  </th>-->
<!--                  <th class="text-left">-->
<!--                    Max Price (AED)-->
<!--                  </th>-->
<!--                </tr>-->
<!--                </thead>-->
<!--                <tbody>-->
<!--                <tr-->
<!--                    v-for="item in ppsfTableItems"-->
<!--                    :key="item.beds"-->
<!--                >-->
<!--                  <td>{{ item.beds }}</td>-->
<!--                  <td>{{ item.count }}</td>-->
<!--                  <td>{{ item.avg_price }}</td>-->
<!--                  <td>{{ item.min_price }}</td>-->
<!--                  <td>{{ item.max_price }}</td>-->
<!--                </tr>-->
<!--                </tbody>-->
<!--              </template>-->
<!--            </v-simple-table>-->
<!--          </div>-->
<!--        </v-col>-->
<!--      </v-row>-->
      <!--      <v-row>-->
      <!--        <v-col cols="12">-->
      <!--          <v-card-title class="pt-0">-->
      <!--            Avg/Min/Max Prices by Bedrooms-->
      <!--          </v-card-title>-->
      <!--          <div class="bg-white">-->
      <!--            <apexchart ref="avgPbbGraph" type="bar" height="1000" :options="chartOptions3"-->
      <!--                       :series="series3"></apexchart>-->
      <!--          </div>-->
      <!--        </v-col>-->
      <!--      </v-row>-->
    </v-card>
  </v-container>
</template>

<script>
import {mapState} from "vuex";
import apexchart from "vue-apexcharts";
import ReportDevelopmentDetails from "@/view/pages/realstate/reports/inc/ReportDevelopmentDetails";
import ReportProjectDetails from "@/view/pages/realstate/reports/inc/ReportProjectDetails";
import {
  REPORT_PT_GRAPH,
  REPORT_PPSF_GRAPH,
  REPORT_PBB_GRAPH,
  REPORT_TRANSACTIONS_LIST,
  REPORT_PBB_TABLE,
  REPORT_YEARLY_GRAPH
} from "@/core/services/store/reports.module";

export default {
  name: "ads-report-details",
  components: {
    apexchart,
    ReportDevelopmentDetails,
    ReportProjectDetails
  },
  data() {
    return {
      panel: 0,
      headers: [
        {text: 'Date', value: 'date', name: 'date', filterable: false, sortable: false},
        {text: 'Type', value: 'type', name: 'type', filterable: false, sortable: false},
        {text: 'Primary/Resale', value: 'data_point', name: 'data_point', filterable: false, sortable: false},
        {text: 'Location', value: 'location', name: 'location', filterable: false, sortable: false},
        // {text: 'Master Development', value: 'development', name: 'development', filterable: false, sortable: false},
        // {text: 'Sub Development 1', value: 'community', name: 'community', filterable: false, sortable: false},
        // {text: 'Sub Development 2/Project', value: 'sub_community', name: 'sub_community', filterable: false, sortable: false},
        // {text: 'Project', value: 'project', name: 'project', filterable: false, sortable: false},
        {text: 'Beds', value: 'beds', name: 'beds', filterable: false, sortable: false},
        {text: "Price", value: "price", name: "price", filterable: false, sortable: false},
        {text: "Built Up Area", value: "unit_size", name: "unit_size", filterable: false, sortable: false},
        {text: "Plot Area", value: "plot_size", name: "plot_size", filterable: false, sortable: false},
        {text: "Price Per SqFt", value: "price_per_sqft", name: "price_per_sqft", filterable: false, sortable: false},
        // {text: "Villa Type", value: "villa_type", name: "villa_type", filterable: false, sortable: false},
        {text: "Property Type", value: "unit_type", name: "unit_type", filterable: false, sortable: false},
        {text: "Floor Level", value: "floor_level", name: "floor_level", filterable: false, sortable: false},
      ],
      isLoaded: true,
      draw: 1,
      searchQuery: "",
      loading: true,
      pagination: {
        page: 1,
        rowsPerPage: 25,
        totalItems: 0,
        sortDesc: "desc"
      },
      totalRecords: 0,
      rowsPerPageItems: [25, 50, 100, 150, 200],
      headerindex: "",
      columns: [],
      items: [],
      titlesFilter: [],
      isTitlesLoaded: false,
      searchFormData: {},
      transactionCount: [],
      series: [
        {
          name: 'Transactions',
          type: 'column',
          data: []
        },
        {
          name: 'Sales',
          type: 'line',
          data: []
        }
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: 'line',
        },
        stroke: {
          width: [0, 4]
        },
        dataLabels: {
          enabled: false,
          enabledOnSeries: [1]
        },
        xaxis: {},
        yaxis: [
          {
            title: {
              text: 'Transactions',
            },
            labels: {
              formatter: function (value) {
                return value.toLocaleString('en-US');
              }
            }
          },
          {
            opposite: true,
            title: {
              text: 'Sales'
            },
            labels: {
              formatter: function (value) {
                return value.toLocaleString('en-US');
              }
            }
          }]
      },
      series1: [
        {
          name: 'Transactions',
          type: 'column',
          data: []
        },
        {
          name: 'Sales',
          type: 'line',
          data: []
        }
      ],
      chartOptions1: {
        chart: {
          height: 350,
          type: 'line',
        },
        stroke: {
          width: [0, 4]
        },
        dataLabels: {
          enabled: false,
          enabledOnSeries: [1]
        },
        xaxis: {},
        yaxis: [
          {
            title: {
              text: 'Transactions',
            },
            labels: {
              formatter: function (value) {
                return value.toLocaleString('en-US');
              }
            }
          },
          {
            opposite: true,
            title: {
              text: 'Sales'
            },
            labels: {
              formatter: function (value) {
                return value.toLocaleString('en-US');
              }
            }
          }]
      },
      series2: [
        {
          name: 'Price',
          type: 'line',
          data: []
        }
      ],
      chartOptions2: {
        chart: {
          height: 350,
          type: 'line',
        },
        stroke: {
          width: [4]
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [0]
        },
        xaxis: {},
        yaxis: [
          {
            title: {
              text: 'Price',
            },
            labels: {
              formatter: function (value) {
                return value.toLocaleString('en-US');
              }
            }
          }]
      },
      series3: [
        {
          name: 'Avg. Price',
          data: []
        },
        {
          name: 'Min. Price',
          data: []
        },
        {
          name: 'Max. Price',
          data: []
        }
      ],
      chartOptions3: {
        chart: {
          height: 1000,
          type: 'bar',
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '100%',
            endingShape: 'rounded'
          }
        },
        colors: ['#44b973', '#0c69b6', '#081746'],
        stroke: {
          show: true,
          width: 5,
          colors: ['transparent']
        },
        fill: {
          opacity: 1
        },
        dataLabels: {
          enabled: true,
          formatter: function (value) {
            return value.toLocaleString('en-US');
          }
        },
        xaxis: {},
        yaxis: [
          {
            title: {
              text: 'Bedrooms',
            },
            labels: {
              formatter: function (value) {
                return value.toLocaleString('en-US');
              }
            }
          }]
      },
      detailItem: {},
      vendor_image: '',
      vendor_name: '',
      vendor_orn: '',
      vendor_emails: [],
      vendor_phones: [],
      vendor_websites: [],
      vendor_addresses: [],
      ppsfTableItems: []
    };
  },
  watch: {
    params: {
      handler() {
        this.loadData().then(response => {
          this.items = response.items;
          this.totalRecords = response.total;
          this.getYearlyGraph();
        });
      },
      deep: true
    }
  },
  computed: {
    params() {
      return {
        ...this.pagination,
        query: this.searchQuery ? this.searchQuery : ''
      };
    },
    ...mapState({
      errors: state => state.reports.errors
    })
  },
  mounted() {
    for (let i = 0; i < this.headers.length; i++) {
      this.columns.push(
          '&columns[' + i + '][data]=' + this.headers[i].value +
          '&columns[' + i + '][name]=' + this.headers[i].name +
          '&columns[' + i + '][searchable]=' + this.headers[i].filterable +
          '&columns[' + i + '][orderable]=' + this.headers[i].sortable +
          '&columns[' + i + '][search][value]=' +
          '&columns[' + i + '][search][regex]=' + false
      );
    }
    this.searchFormData = this.$route.query
  },
  methods: {
    // searchQueryData: function (e) {
    //   if (e.keyCode === 13) {
    //     this.searchQuery = e.target.value;
    //   }
    // },
    getHeaderIndex(name) {
      let that = this;
      this.headers.filter(function (ele, i) {
        if (name == ele.name) {
          that.headerindex = i;
        }
      });
    },
    loadData() {
      this.loading = true;
      this.isLoaded = true;
      return new Promise((resolve) => {
        let params = this.params;
        params.length = params.itemsPerPage; //set how many records to fetch per page
        params.start =
            params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
        params.draw = this.draw;
        let query = Object.keys(params).map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
        }).join('&');
        let searchFormDataQuery = Object.keys(this.searchFormData).map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(this.searchFormData[key])
        }).join('&');
        if (searchFormDataQuery) query += '&' + searchFormDataQuery;
        this.getHeaderIndex(params.sortBy[0]);
        query +=
            "&search[value]=" +
            params.query +
            "&search[regex]=" +
            false +
            "&order[0][column]=" +
            (params.sortBy.length === 0 ? 0 : this.headerindex) +
            "&order[0][dir]=" +
            (params.sortDesc[0] ? "desc" : "asc");
        this.$store.dispatch(REPORT_TRANSACTIONS_LIST, {q: query, columns: this.columns}).then((res) => {
          this.draw++;
          let items = res.records.data;
          let total = res.records.recordsFiltered;
          if (res.records.detail_item) this.detailItem = res.records.detail_item
          if (res.records.vendor_image) this.vendor_image = res.records.vendor_image
          if (res.records.vendor_name) this.vendor_name = res.records.vendor_name
          if (res.records.vendor_orn) this.vendor_orn = res.records.vendor_orn
          if (res.records.vendor_emails.length > 0) this.vendor_emails = res.records.vendor_emails
          if (res.records.vendor_phones.length > 0) this.vendor_phones = res.records.vendor_phones
          if (res.records.vendor_websites.length > 0) this.vendor_websites = res.records.vendor_websites
          if (res.records.vendor_addresses.length > 0) this.vendor_addresses = res.records.vendor_addresses
          this.loading = !this.loading;
          this.isLoaded = !this.isLoaded;
          resolve({
            items,
            total
          });
        });
      });
    },
    getYearlyGraph() {
      let query = Object.keys(this.searchFormData).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(this.searchFormData[key])
      }).join('&');
      this.$store.dispatch(REPORT_YEARLY_GRAPH, {q: query}).then((res) => {
        this.$refs.yearlyGraph.updateOptions(
            {xaxis: {categories: res.records.years}}
        )
        this.$refs.yearlyGraph.updateSeries([
          {data: res.records.transactions},
          {data: res.records.sales},
        ])
        this.getPtGraph();
      });
    },
    getPtGraph() {
      let query = '';
      let searchFormDataQuery = Object.keys(this.searchFormData).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(this.searchFormData[key])
      }).join('&');
      if (searchFormDataQuery) query = searchFormDataQuery;
      this.$store.dispatch(REPORT_PT_GRAPH, {q: query}).then((res) => {
        this.$refs.ptGraph.updateOptions(
            {xaxis: {categories: res.records.unit_types}}
        )
        this.$refs.ptGraph.updateSeries([
          {data: res.records.transactions},
          {data: res.records.sales},
        ])
        this.getPpsfGraph();
      });
    },
    getPpsfGraph() {
      let query = Object.keys(this.searchFormData).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(this.searchFormData[key])
      }).join('&');
      this.$store.dispatch(REPORT_PPSF_GRAPH, {q: query}).then((res) => {
        this.$refs.avgPpsfGraph.updateOptions(
            {xaxis: {categories: res.records.years}}
        )
        this.$refs.avgPpsfGraph.updateSeries([
          {data: res.records.sales},
        ])
        // this.getPbbTable();
      });
    },
    getPbbfGraph() {
      let query = Object.keys(this.searchFormData).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(this.searchFormData[key])
      }).join('&');
      this.$store.dispatch(REPORT_PBB_GRAPH, {q: query}).then((res) => {
        this.$refs.avgPbbGraph.updateOptions(
            {xaxis: {categories: res.records.beds}}
        )
        this.$refs.avgPbbGraph.updateSeries([
          {data: res.records.avg_prices},
          {data: res.records.min_prices},
          {data: res.records.max_prices},
        ])
      });
    },
    getPbbTable() {
      let query = Object.keys(this.searchFormData).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(this.searchFormData[key])
      }).join('&');
      this.$store.dispatch(REPORT_PBB_TABLE, {q: query}).then((res) => {
        this.ppsfTableItems = res.records
      });
    },
    hitAdvanceSearch() {
      this.pagination.page = 1
      this.loadData().then(response => {
        this.items = response.items;
        this.totalRecords = response.total;
        this.getYearlyGraph();
      });
    },
    resetSearchForm() {
      this.pagination.page = 1
      this.searchFormData = {};
      this.loadData().then(response => {
        this.items = response.items;
        this.totalRecords = response.total;
        this.getYearlyGraph();
      });
    },
    drChange(v) {
      if (v) {
        if (v === "1") {
          let date = new Date();
          let cdate = new Date();
          let lastmonths = new Date(cdate.setMonth(cdate.getMonth() - 1));
          let toDate = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
          let fromDate = lastmonths.getFullYear() + '-' + ('0' + (lastmonths.getMonth() + 1)).slice(-2) + '-' + ('0' + lastmonths.getDate()).slice(-2);
          this.searchFormData.toDateSearch = toDate
          this.searchFormData.fromDateSearch = fromDate
        } else if (v === "2") {
          let date = new Date();
          let cdate = new Date();
          let last3months = new Date(cdate.setMonth(cdate.getMonth() - 2));
          let toDate = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
          let fromDate = last3months.getFullYear() + '-' + ('0' + (last3months.getMonth())).slice(-2) + '-' + ('0' + last3months.getDate()).slice(-2);
          this.searchFormData.toDateSearch = toDate
          this.searchFormData.fromDateSearch = fromDate
        } else if (v === "3") {
          let date = new Date();
          let cdate = new Date();
          let last6months = new Date(cdate.setMonth(cdate.getMonth() - 5));
          let toDate = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
          let fromDate = last6months.getFullYear() + '-' + ('0' + (last6months.getMonth())).slice(-2) + '-' + ('0' + last6months.getDate()).slice(-2);
          this.searchFormData.toDateSearch = toDate
          this.searchFormData.fromDateSearch = fromDate
        } else if (v === "4") {
          let date = new Date();
          let toDate = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
          let fromDate = (date.getFullYear() - 1) + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
          this.searchFormData.toDateSearch = toDate
          this.searchFormData.fromDateSearch = fromDate
        } else if (v === "5") {
          let date = new Date();
          let toDate = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
          let fromDate = date.getFullYear() + '-01-01';
          this.searchFormData.toDateSearch = toDate
          this.searchFormData.fromDateSearch = fromDate
        }
      } else {
        this.searchFormData.toDateSearch = ''
        this.searchFormData.fromDateSearch = ''
      }
    },
  }
};
</script>
